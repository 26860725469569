module.exports = {
  url: 'https://a.network', // do no include trailing backslash
  postsPerHomePage: 7,
  postsPerPage: 6,
  authorDefaultEmail: 'contact@a.network',
  author: 'a.network', // author for RSS / SEO schema
  authorURL: 'http://localhost:8000', // url for author/publisher schema
  twitterHandle: '@anetwork', // for twitter cards
  shortTitle: 'a.network', // for app manifest
  image: '/logos/anetwork.jpg', // OG default share image, 1200 x 1200 recommended
  imageWidth: 1200, // change to actual width of share image
  imageHeight: 1200, // ditto actual height
  logo: 'favicon.png', // logo for SEO, RSS, and app manifest
  logoAlt: 'a.network',
  backgroundColor: '#e9e9e9', // offline manifest
  themeColor: '#121212', // offline manigest
  copyright: 'Copyright © 2022 a.network', // copyright for RSS feed
  publisher: 'a.network',
  shareImage: '/logos/anetwork.jpg', // OG default share image, 1200 x 1200 recommended
  shareImageWidth: 1200, // change to actual width of share image
  shareImageHeight: 1200, // ditto actual height
  titleAlt: 'a.network',
  title: 'a.network',
  description: 'a.network',
  theme: 'black',
  slug: '',
}
