require('dotenv').config({
  path: `.env`,
})
const config = require('./config')

const contentfulConfig = {
  spaceId: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_DELIVERY_API,
  environment: process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
  host: process.env.GATSBY_CONTENTFUL_HOST,
}

module.exports = {
  siteMetadata: {
    title: config.title,
    mapboxToken: process.env.GATSBY_MAPBOX_API_TOKEN_SECRET,
  },
  pathPrefix: '/anetwork',
  plugins: [
    'gatsby-transformer-sharp',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sharp',
    {
      resolve: `gatsby-plugin-breadcrumb`,
      options: {
        defaultCrumb: {
          // location: required and must include the pathname property
          location: {
            pathname: '/',
          },
          // crumbLabel: required label for the default crumb
          crumbLabel: 'Home',
          // all other properties optional
          crumbSeparator: ' / ',
        },
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `a.network`,
        short_name: `a.network`,
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#ffffff`,
        display: `standalone`,
        cache_busting_mode: 'none',
        icon: `src/assets/icon.png`,
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: 'UA-21738219-17',
      },
    },
    {
      resolve: `gatsby-plugin-offline`,
      options: {
        workboxConfig: {
          globPatterns: ['**/*'],
        },
      },
    },
    {
      resolve: `gatsby-plugin-styled-components`,
      options: {
        displayName: process.env.NODE_ENV !== `production`,
        fileName: false,
      },
    },
    {
      resolve: 'gatsby-source-contentful',
      options: contentfulConfig,
    },
    {
      resolve: 'gatsby-plugin-google-fonts',
      options: {
        fonts: ['Open Sans:300,400,600'],
        display: 'swap',
      },
    },
    {
      resolve: 'gatsby-plugin-web-font-loader',
      options: {
        custom: {
          families: ['FS Joey'],
          urls: ['/fonts/fonts.css'],
        },
      },
    },
    {
      resolve: `gatsby-source-instagram`,
      options: {
        username: `ayzenberggroup`,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: { name: 'src', path: `${__dirname}/src` },
    },
    {
      resolve: `gatsby-plugin-s3`,
      options: {
        bucketName:
          process.env.GATSBY_AWS_TARGET_BUCKET_NAME ||
          'anetcontent-3yvivwi0yvy3-development',
        acl: null,
      },
    },
  ],
}
